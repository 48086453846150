import React from "react";
import { Link } from "react-router-dom";
import Header from "../Components/Header";
import Rightcontainer from "../Components/Rightcontainer";
const Gamerules = () => {
  return (
    <div>
        <div className="leftContainer" style={{minHeight:'100vh',height:'100%'}}>
            
      <div className="m-3 py-5 pt-3 px-3">
        <h1><strong>Ludo Rules</strong></h1>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Ludo Rules
            </li>
          </ol>
        </nav>
        <div className="row">
          <div className="col-12">
            <h4><strong>Game Rules:</strong></h4>
  <h1>गेम नियम</h1>

    <div>
        <h3>नियम 1: गेम छोड़ने पर कार्रवाई</h3>
        <p>यदि दोनों प्लेयर्स ने कोड Join करके गेम प्ले कर लिया हो और दूसरा प्लेयर गोटी ओपन होने के बाद लेफ्ट करता है तो Opponent प्लेयर को पूरा लूज़ दिया जाएगा।</p>
    </div>

    <div>
        <h3>नियम 2: ऑटो एग्जिट के परिणाम</h3>
        <p>यदि दोनों प्लेयर्स गेम प्ले के लिए स्टार्ट करते हैं, लेकिन एक प्लेयर गेम में नहीं गया और गेम प्ले हो गया, और एक प्लेयर की गोटी ओपन हो गई, और दूसरा प्लेयर ऑटो एग्जिट हो जाता है, तो उस प्लेयर को पूरा लूज़ दिया जाएगा। अतः अपना नेट प्रॉब्लम चेक करके खेले, यह स्वयं की जिम्मेदारी होगी।</p>
    </div>

    <div>
        <h3>नियम 3: रिजल्ट डालने की समय सीमा</h3>
        <p>गेम समाप्त होने के 15 मिनट के अंदर रिजल्ट डालना आवश्यक है। अन्यथा Opponent के रिजल्ट के आधार पर गेम अपडेट कर दिया जाएगा, चाहे आप जीते या हारे और इसमें पूरी ज़िम्मेदारी आपकी होगी। इसमें बाद में कोई बदलाव नहीं किया जा सकता है।</p>
    </div>

    <div>
        <h3>नियम 4: सही स्क्रीनशॉट सबमिशन</h3>
        <p>विन होने के बाद अगर आप गलत स्क्रीनशॉट डालते हैं, तो गेम को सीधा कैंसिल कर दिया जाएगा। यदि आप स्क्रीनशॉट लेना भूल गए हैं, तो पहले लाइव चैट में एडमिन से संपर्क करें और उनके बताये अनुसार ही रिजल्ट पोस्ट करें।</p>
    </div>

    <div>
        <h3>नियम 5: गेम कैंसिलेशन</h3>
        <p>अगर दोनों प्लेयर्स की टोकन (गोटी) घर से बाहर नहीं आई हो, तो लेफ्ट होने पर गेम कैंसिल किया जा सकता है। 'कैंसिल' प्रूफ करने के लिए वीडियो आवश्यक होगा। 'कैंसिल' रिजल्ट डालने के बाद अगर आप गेम खेलते हैं और जीत जाते हैं, तो उसमें हमारी कोई ज़िम्मेदारी नहीं होगी। अतः गेम कैंसिल करने के बाद दुबारा शुरू न करें, अन्यथा वो कैंसिल ही माना जाएगा।</p>
    </div>

    <div>
        <h3>नियम 6: रिजल्ट बदलाव की स्थिति</h3>
        <p>एक बार रिजल्ट डालने के बाद बदला नहीं जा सकता है, इसलिए सोच-समझकर रिजल्ट पोस्ट करें। गलत रिजल्ट डालने पर पेनल्टी भी लगाई जाएगी, चाहे आपने वो गलती से डाला हो या जानबूझकर।</p>
    </div>

    <div>
        <h3>नियम 7: Fresh आईडी गेम नियम</h3>
        <p>Fresh आईडी के मामले में केवल पासा के उपयोग के प्रमाण पर गेम रद्द किया जाएगा, भले ही गोटी खुली हो। गेम का रिकॉर्ड होना आवश्यक है। अगर किसी ने अपनी आईडी का नाम "नो फ्रेश" रखा है, तो "नो फ्रेश" आईडी ही टेक करे। जिनका इंटरेस्ट हो, वही टेबल टेक करें। इसका अंतिम निर्णय एडमिन देगा।</p>
    </div>

          </div>
        </div>
      </div>
      </div>
      <div className="rightContainer">
          <Rightcontainer/>
      </div>
    </div>
  );
};
export default Gamerules;